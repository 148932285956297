import { initMap } from "./map";
import {
  areAllValuesNull,
  groupDataByCountry,
  removeDuplicates,
} from "./utils";
import { filterDealersData, fetchAllDealersData, createSvg } from "./utils";

const filters = {};
let defaultCountry = null;

let disabledTabs = new Set();

function renderDealer(data, index) {
  const dealerElement = createDealerElement(data);
  const resultContainer = document.getElementsByClassName("result")[0];

  const ul = document.createElement("ul");
  ul.className = "p-card-box";
  ul.appendChild(dealerElement);

  const div = document.createElement("div");
  div.setAttribute("data-selectbox", `"${index + 1}`);
  div.appendChild(ul);

  resultContainer.appendChild(div);
}

function createCompanyCard(dealerInfo) {
  const {
    companyName,
    address,
    phoneNumber,
    fax,
    email,
    webSiteUrl,
    webSiteLabel,
    category,
    brand,
    industry,
  } = dealerInfo;

  let categoryArr = category && category.split(",");
  let industryArr = industry && industry.split(",");
  let brandArr = brand && brand.split(",");

  const cardContainer = document.createElement("div");
  cardContainer.className = "p-card-box";

  const h3 = document.createElement("h3");
  h3.innerText = companyName;
  cardContainer.appendChild(h3);

  const contentDiv = document.createElement("div");
  contentDiv.className = "p-card-box__cont";

  const companyNameDiv = document.createElement("h6");
  companyNameDiv.innerText = companyName;
  companyNameDiv.className = "p-card-box__title";
  contentDiv.appendChild(companyNameDiv);

  if (address) {
    const addressDiv = document.createElement("p");
    addressDiv.className = "c-text";
    addressDiv.innerText = address;
    contentDiv.appendChild(addressDiv);
  }

  if (phoneNumber) {
    const phoneDiv = document.createElement("p");
    phoneDiv.className = "c-text";
    phoneDiv.innerText = phoneNumber;
    contentDiv.appendChild(phoneDiv);
  }

  if (fax) {
    const faxDiv = document.createElement("p");
    faxDiv.className = "c-text";
    faxDiv.innerText = fax;
    contentDiv.appendChild(faxDiv);
  }

  if (email) {
    const emailDiv = document.createElement("p");
    emailDiv.className = "c-text";
    emailDiv.innerText = email;
    contentDiv.appendChild(emailDiv);
  }

  if (webSiteLabel) {
    const labelDiv = document.createElement("a");
    labelDiv.className = "c-link";
    labelDiv.innerText = webSiteLabel;
    labelDiv.target = "_blank";
    labelDiv.href = webSiteUrl;

    contentDiv.appendChild(labelDiv);
  }

  if (brandArr && brandArr.length > 0) {
    const ul = document.createElement("ul");
    ul.className = "brand-list-ul";

    let brandClass = "brand-topcon";
    brandArr.forEach((brandItem) => {
      let brandEl = brandItem.toLowerCase();
      if (brandEl == "sokkia") {
        brandClass = "brand-sokkia";
      } else if (brandEl == "xyeez") {
        brandClass = "brand-xyeez";
      } else {
        brandClass = "brand-topcon";
      }
      const li = document.createElement("li");
      li.textContent = brandItem;
      li.className = brandClass;
      ul.appendChild(li);
    });
    contentDiv.appendChild(ul);
  }
  if (industryArr && industryArr.length > 0) {
    const ul = document.createElement("ul");
    ul.className = "industry-list-ul";

    industryArr.forEach((industryItem) => {
      const li = document.createElement("li");
      li.textContent = industryItem;
      li.className = "industry-list-item";
      ul.appendChild(li);
    });

    contentDiv.appendChild(ul);
  }
  if (categoryArr && categoryArr.length > 0) {
    const ul = document.createElement("ul");
    ul.className = "category-list-ul";

    categoryArr.forEach((categoryItem) => {
      const li = document.createElement("li");
      li.textContent = categoryItem;
      li.className = "category-list-item";
      ul.appendChild(li);
    });

    contentDiv.appendChild(ul);
  }
  return contentDiv;
}

function createDealerElement(dealer) {
  const dealerDiv = document.createElement("li");
  dealerDiv.className = "p-card-box__item";

  const { countryName, dealers } = dealer;

  const h5 = document.createElement("h5");
  h5.className = "p-card-box__label";
  h5.innerText = countryName;
  h5.style.textTransform = "capitalize";
  dealerDiv.appendChild(h5);

  const dealerListContainer = document.createElement("div");
  dealerListContainer.className = "dealers-list-li";

  dealers.forEach((dealerInfo) => {
    const companyInformation = createCompanyCard(dealerInfo);

    return dealerListContainer.appendChild(companyInformation);
  });

  dealerDiv.appendChild(dealerListContainer);
  return dealerDiv;
}

function createSingleOptionTab(tab, tabKey, parentContainer, uniqueData) {
  const li = document.createElement("li");
  li.classList.add("p-selector__box");
  li.setAttribute("id", tab);
  const tabName = document.createElement("span");
  tabName.className = "p-selector__label";
  tabName.innerText = tabKey;

  const mainDiv = document.createElement("div");
  mainDiv.classList.add("c-select");

  const selectElement = document.createElement("select");
  selectElement.className = "c-select__box disabled-box";
  selectElement.setAttribute("disabled", "");

  const option1 = document.createElement("option");
  const buttonVal = document.createTextNode(uniqueData[0]);
  option1.appendChild(buttonVal);
  selectElement.appendChild(option1);
  mainDiv.appendChild(selectElement);

  filters[tab] = uniqueData[0];

  li.appendChild(tabName);
  li.appendChild(mainDiv);
  parentContainer.appendChild(li);
}

function createTabDropdown(tab, parentContainer, label, val) {
  const dealersearch = document.querySelector("dealersearch");
  const queryParams = new URLSearchParams(window.location.search);
  const category = queryParams.get("category");
  const brand = queryParams.get("brand");
  const industry = queryParams.get("industry");
  const country = queryParams.get("country");

  const lowerTabVal = val == "countryName" ? "country" : val.toLowerCase();

  const optionContainer = document.createElement("ul");
  optionContainer.className = `ul-container-${val}`;
  optionContainer.id = "optionContainer";
  optionContainer.style.display = "none";
  optionContainer.classList.add("p-selector__panel", "js-select-panel");
  optionContainer.setAttribute("data-selectlabel", lowerTabVal);
  const li = document.createElement("li");
  li.classList.add("p-selector__box", "js-select");
  li.setAttribute("id", val);

  const tabName = document.createElement("span");
  tabName.className = "p-selector__label";
  tabName.innerText = tab;

  const mainDiv = document.createElement("div");
  mainDiv.classList.add("c-select");

  const selectElement = document.createElement("select");
  selectElement.classList.add(
    "c-select__box",
    "c-select__box--dis",
    "js-select-input"
  );
  selectElement.setAttribute("disabled", "");

  const arrowSpan = document.createElement("span");
  if (arrowSpan) arrowSpan.classList.add("c-select__arrow", "js-select-icon");

  const arrowImage = createSvg();

  let defaultLabel = label;

  if (
    (dealersearch.getAttribute("data-default-country") || country) &&
    val == "countryName"
  ) {
    const countryValue = country ? country : defaultCountry;
    defaultLabel = countryValue;
    filters["countryName"] = countryValue;
  }
  if (val == "category" && category) {
    filters["category"] = category;
    defaultLabel = category;
  }
  if (val == "brand" && brand) {
    filters["brand"] = brand;
    defaultLabel = brand;
  }
  if (val == "industry" && industry) {
    filters["industry"] = industry;
    defaultLabel = industry;
  }

  arrowSpan.appendChild(arrowImage);
  const selectDefaultSpan = document.createElement("span");
  selectDefaultSpan.classList.add("c-select-default", "js-select-btn");
  selectDefaultSpan.setAttribute("data-val", `${val}-select`);
  selectDefaultSpan.textContent = defaultLabel;

  mainDiv.appendChild(selectElement);
  mainDiv.appendChild(arrowSpan);
  mainDiv.appendChild(selectDefaultSpan);
  li.appendChild(tabName);
  li.appendChild(mainDiv);

  li.appendChild(optionContainer);
  parentContainer.appendChild(li);
}

window.addEventListener("load", async function (event) {
  const dealersearchElement = document.querySelector(".filter");
  const dealersearch = document.querySelector("dealersearch");
  const mapEl = document.querySelector("#map");
  const hideMap = dealersearch && dealersearch.getAttribute("data-hide-map");
  const defaultCountryAttribute =
    dealersearch && dealersearch.getAttribute("data-default-country");
  defaultCountry = defaultCountryAttribute;
  const googleApi = dealersearch && dealersearch.getAttribute("data-api-key");

  if (dealersearch && dealersearchElement) {
    if (hideMap !== "true" && googleApi && mapEl) {
      if (mapEl) mapEl.className = "p-map__image";
      const mapDiv = document.createElement("div");
      mapDiv.className = "l-contents__map";
      const secondMapDiv = document.createElement("div");
      secondMapDiv.className = "p-map";
      mapDiv.appendChild(secondMapDiv);
      secondMapDiv.appendChild(mapEl);

      const lat = dealersearch.getAttribute("data-google-lat");
      const long = dealersearch.getAttribute("data-google-long");
      const googleMapLabel = dealersearch.getAttribute("data-googlemap");

      const dealerSearchArea =
        document.getElementsByClassName("dealersearchmap")[0];
      if (dealerSearchArea && mapDiv) dealerSearchArea.appendChild(mapDiv);

      const divMapButton = document.createElement("div");
      divMapButton.className = "p-map__button";

      const link = `https://www.google.com/maps/@?api=1&map_action=map&center=${lat}%2C${long}&zoom=5
  `;
      const aButton = document.createElement("a");
      aButton.className = "c-button";
      aButton.href = link;
      aButton.target = "_blank";

      const spanText = document.createElement("span");
      spanText.textContent = googleMapLabel;

      aButton.appendChild(spanText);
      divMapButton.appendChild(aButton);
      dealerSearchArea.append(divMapButton);
    }
    // needed for the get requests
    const filterPath = dealersearch.getAttribute("data-filter-path");
    const endpoint = dealersearch.getAttribute("data-graphql-dealers-endpoint");

    let res = await fetchAllDealersData(filterPath, endpoint);

    let allData = await res?.data?.dealerList?.items;

    if (res && allData) {
      let data = allData.filter((dealer) => !dealer._path.includes("company"));

      await createDropdowns(data, dealersearch, dealersearchElement);
      await fillDropdownsAndRenderRes(
        allData,
        data,
        dealersearch,
        dealersearchElement
      );
    }

    // for testing with dummy data
    // let res = dealersData;
    // let data = res.data.dealerList.items;
    // let allData = res.data.dealerList.items;
  }
});

async function createDropdowns(data, dealersearch, dealersearchElement) {
  const selectLabel = dealersearch.getAttribute("data-select-label");

  const parentContainer = document.createElement("ul");
  parentContainer.classList.add("p-selector", "c-flex", "c-flex--cg");
  const tabs = {};
  for (const { name, value } of dealersearch.attributes) {
    if (
      name.startsWith("data-") &&
      name.endsWith("-label") &&
      name != "data-select-label"
    ) {
      tabs[value] = name.split("-")[1]; // makes the labels
      filterName = value;
      if (filterName == "country") {
        filterName = "countryName";
      } else {
        filters[filterName] = null;
      }
    }
  }
  // makes the tab dropdown
  Object.keys(tabs).forEach((tabKey) => {
    let val = tabs[tabKey];
    if (val == "country") val = "countryName";
    const uniqueData = removeDuplicates(
      data
        .map((it) => (val && val in it && it[val] ? it[val].split(",") : []))
        .flat()
    );

    if (uniqueData.length == 1) {
      disabledTabs.add(val);
      createSingleOptionTab(val, tabKey, parentContainer, uniqueData);
      dealersearchElement.appendChild(parentContainer); // Append the parent container to dealersearchElement
    } else if (uniqueData.length > 1) {
      createTabDropdown(tabKey, parentContainer, selectLabel, val);
      dealersearchElement.appendChild(parentContainer); // Append the parent container to dealersearchElement
    } else {
      return;
    }
  });
}

async function fillDropdownsAndRenderRes(
  allData,
  data,
  dealersearch,
  dealersearchElement
) {
  for (const e of document.querySelectorAll("#optionContainer")) {
    e.innerHTML = "";
  }
  const dropdownTabs = document.getElementsByClassName("p-selector__box");
  const apiKey = dealersearch.getAttribute("data-api-key");

  [...dropdownTabs].forEach((tab, i) => {
    let tabKey = tab.id;
    if (tabKey == "country") tabKey = "countryName";

    if (disabledTabs.has(tabKey)) {
      return;
    }

    // filter the data by every other filter other than this tab,
    // then with the data result, get unique vals for this tab;
    let filteredData = [];
    for (const item of data) {
      let shouldKeep = true;
      for (let [filterKey, filterVal] of Object.entries(filters)) {
        if (
          item[filterKey] &&
          filterKey != tabKey &&
          filterVal != null &&
          filterKey in item
        ) {
          const itemValues = item[filterKey].split(",");
          if (!itemValues.includes(filterVal)) {
            shouldKeep = false;
            break;
          }
        }
      }
      if (shouldKeep) filteredData.push(item);
    }
    let uniqueData = [];
    for (const item of filteredData) {
      item[tabKey] && item[tabKey].split(",").map((v) => uniqueData.push(v));
    }
    uniqueData = removeDuplicates(uniqueData);

    const lowerCaseLabel =
      tab.id == "countryName" ? "country" : tab.id.toLowerCase();

    const selection = document.querySelector(
      `[data-selectlabel="${lowerCaseLabel}"]`
    );

    const select = document.querySelector(`[data-val="${tab.id}-select"]`);

    // Create the default option ('Select')
    const defaultOption = document.createElement("li");
    defaultOption.classList.add("p-selector__panel__item", "js-select-val");
    defaultOption.textContent = "Select";
    defaultOption.setAttribute("data-val", "Select");
    defaultOption.addEventListener("click", () => {
      // Clicking this clears the corresponding filter.
      filters[tabKey] = null;

      setTimeout(() => {
        fillDropdownsAndRenderRes(
          allData,
          data,
          dealersearch,
          dealersearchElement
        );
      }, 5);
    });
    if (selection) selection.appendChild(defaultOption);

    // creating the tab options
    uniqueData.length > 0 &&
      uniqueData.forEach((val) => {
        let key = tab.id;
        if (key == "country") key = "countryName";

        // do not add current selected filter to the dropdown option
        if (val !== filters[key]) {
          const option = document.createElement("li");
          option.setAttribute("data-val", val);
          const buttonVal = document.createTextNode(val);
          option.appendChild(buttonVal);

          option.classList.add("p-selector__panel__item", "js-select-val");

          option.id = `option-${val}`;
          if (selection) selection.appendChild(option);

          option.addEventListener("click", (e) => {
            e.preventDefault();
            select.textContent = val;

            filters[key] = val;
            // Regenerate the dropdown options. But first wait a bit so that JQuery has time
            // to respond to the click event on this element before it gets deleted.
            // This sucks haha.
            setTimeout(() => {
              fillDropdownsAndRenderRes(
                allData,
                data,
                dealersearch,
                dealersearchElement
              );
            }, 5);
          });
        }
      });
  });

  // Display list of selected dealers.
  const currDataContainer = document.getElementsByClassName("result")[0];
  currDataContainer.innerHTML = "";

  const filteredDealersData = filterDealersData(data, filters).filter(
    (dealer) => dealer._path && !dealer._path.includes("company")
  );

  const filteredData = areAllValuesNull(filters) ? data : filteredDealersData;
  const hideMap = dealersearch.getAttribute("data-hide-map");

  if (filteredData && allData) {
    if (apiKey && hideMap !== "true") {
      await initMap(filteredData, allData, apiKey);
    }

    const groupedData = groupDataByCountry(filteredData);

    groupedData.forEach((dealer, i) => {
      renderDealer(dealer, i);
    });
  }
}
