"use strict";
import $ from "jquery";

function readyFn() {
  var btn = $(".js-select-btn");
  var closeClass = "is-close";

  toggleSelect(btn, 0);
  boxSelect();

  $("body").on("click", ".js-select-btn", function () {
    toggleSelect($(this), 500);
  });

  $("body").on("click", ".js-select-val", function () {
    var value = $(this).data("val");
    var input = $(this).parents(".js-select").find(".js-select-input");
    var defaultBtn = $(this).parents(".js-select").find(".js-select-btn");

    defaultBtn.text("");
    input.html(
      '<option value="'.concat(value, '" selected>').concat(value, "</option>")
    );
    boxSelect($(this));
    toggleSelect($(this), 500);
  });

  function boxSelect(obj) {
    // $("[data-selectbox]").hide(0);

    if (obj) {
      var seleclabelVal = obj.parents(".js-select-panel").data("selectlabel");
      var seleclabelText = obj.data("val");
      obj.parents(".js-select").find(".js-select-btn").text(seleclabelText);
      $('[data-selectbox="'.concat(seleclabelVal, '"]')).fadeIn(500);
    } else {
      $('[data-selectbox="1"]').fadeIn(3000);
    }
  }

  function toggleSelect(objects, time) {
    var animationTime = time || 0;

    if (objects) {
      var container = objects.parents(".js-select");
      var panel = container.find(".js-select-panel");
      var icon = container.find(".js-select-icon");
      panel.slideToggle(animationTime);
      icon.toggleClass(closeClass);
    }
  }
}

$(function () {
  readyFn();
});
