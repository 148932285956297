document.addEventListener("DOMContentLoaded", function () {

  function isMobile() {
    return window.innerWidth <= 768;
  }

  function updateTableHeadersVisibility() {
    var tables = document.querySelectorAll(".ss-text--style-table-responsive .cmp-text table");

    tables.forEach(function (table) {
      table.querySelectorAll("th[scope='col']").forEach(function (th) {
        if (isMobile()) {
          th.style.display = "none";
        } else {
          th.style.display = "";
        }
      });
    });
  }

  updateTableHeadersVisibility();

  window.addEventListener("resize", function () {
    updateTableHeadersVisibility();
  });

  var tables = document.querySelectorAll(".ss-text--style-table-responsive .cmp-text table");
  tables.forEach(function (table) {
    var headers = [];

    table.querySelectorAll("th[scope='col']").forEach(function (th, thIndex) {
      if (thIndex > 0) {
        var headerText = th.textContent.trim();
        headers.push(headerText);
      }
    });

    table.querySelectorAll("tbody tr").forEach(function (row) {
      row.querySelectorAll("td").forEach(function (td, tdIndex) {
        if (headers[tdIndex]) {
          td.setAttribute("data-label", headers[tdIndex]);
        }
      });
    });
  });
});
