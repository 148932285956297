function moveQuotedWordInsideDiv(h2) {
  var originalTitle = h2.innerHTML.trim();
  var match = originalTitle.match(/"([^"]+)"/);

  if (match) {
    var quotedWord = match[1];
    var beforeQuotes = originalTitle
      .substring(0, originalTitle.indexOf('"'))
      .trim();

    h2.innerHTML =
      `${beforeQuotes}` + `<div class="second-line-header">${quotedWord}</div>`;
  }
}


function processProductListComponent(component) {
  var listLis = component.querySelectorAll(".cmp-list__item");

  listLis.forEach((li) => {
    var h2 = li.querySelector(".cmp-teaser__title");
    moveQuotedWordInsideDiv(h2);
  });
}

function moveDateInsideCategoryTagContainer(li) {
  var dateElement = li.querySelector(".cmp-list__item-date");
  var categoryTagContainer = li.querySelector(
    ".cmp-teaser__categorytag-container"
  );

  if (dateElement && categoryTagContainer) {
    categoryTagContainer.appendChild(dateElement);
  }
}

function updateUi() {
  var productListComponent = document.querySelectorAll(
    ".ss-list--style-product-list"
  );

  if (productListComponent) {
    productListComponent.forEach(processProductListComponent);
  }

  var lists = document.querySelectorAll(".list");

  if (lists) {
    lists.forEach((item) => {
      if (item.classList.contains("ss-list--style-column-list")) {
        var li = item.querySelectorAll("li");
        li.forEach((liItem) => {
          moveDateInsideCategoryTagContainer(liItem);
        });
      }
    });
  }
}

if (document.readyState == "loading") {
  console.log(document.readyState, "loading state");
  // still loading, wait for the event
  document.addEventListener("DOMContentLoaded", updateUi);
} else {
  // DOM is ready!
  console.log(document.readyState, "not loading anymore");

  updateUi();
}
