export function transformDealersData(dealersData) {
  if (!dealersData) {
    return []; // Return an empty array if the data structure is invalid
  }

  return dealersData.map((item) => ({
    position: {
      lat: parseFloat(item.latitude),
      lng: parseFloat(item.longitude),
    },
    title: item.companyName,
    phone: item.phoneNumber && item.phoneNumber,
    address: item.address,
  }));
}

export function removeDuplicates(array) {
  return Array.from(new Set(array));
}

export function removeNullProperties(obj) {
  const result = {};
  for (const key in obj) {
    const notNull = obj[key] !== null && obj[key] !== "null";
    if (notNull) {
      result[key] = obj[key];
    }
  }
  return result;
}

export const fetchAllDealersData = async (filterPath, endpoint) => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  let url = endpoint;
  const request = new Request(url, options);
  try {
    const response = await fetch(request);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();

    return data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export function areAllValuesNull(obj) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] !== null) {
      return false;
    }
  }
  return true;
}

export function createSvg() {
  const svgElement = document.createElementNS(
    "http://www.w3.org/2000/svg",
    "svg"
  );

  // Set attributes for the SVG element
  svgElement.setAttribute("width", "28");
  svgElement.setAttribute("height", "28");
  svgElement.setAttribute("viewBox", "0 0 28 28");
  svgElement.setAttribute("fill", "none");

  // Create a circle element
  const circleElement = document.createElementNS(
    "http://www.w3.org/2000/svg",
    "circle"
  );
  circleElement.setAttribute("cx", "14");
  circleElement.setAttribute("cy", "14");
  circleElement.setAttribute("r", "13.5");
  circleElement.setAttribute("stroke", "#1466C6");

  // Create a path element
  const pathElement = document.createElementNS(
    "http://www.w3.org/2000/svg",
    "path"
  );
  pathElement.setAttribute("d", "M20 12L14 17L8 12");
  pathElement.setAttribute("stroke", "#1466C6");
  pathElement.setAttribute("stroke-width", "2");
  pathElement.setAttribute("stroke-miterlimit", "10");

  // Append the circle and path elements to the SVG element
  svgElement.appendChild(circleElement);
  svgElement.appendChild(pathElement);

  return svgElement;
}

export function getUniqueData(data, key, filters) {
  if (key == "country") key = "countryName";
  if (filters[key] != null) {
    return [filters[key]];
  }
  let uniqueData = [];
  for (const item of data) {
    values = item[key].split(",").map((value) => value.trim());
    values.forEach((value) => {
      uniqueData.push(value);
    });
  }
  return removeDuplicates(uniqueData);
}

// not calling api
export function filterDealersData(data, filters) {
  // Remove null properties from the filters
  const filteredFilters = removeNullProperties(filters);

  const newData = [];

  for (const obj of data) {
    let match = true;

    for (const key in filteredFilters) {
      if (obj[key] && !obj[key].split(",").includes(filteredFilters[key])) {
        match = false;
        break;
      }
    }

    // If all filters matched, push the object to the newData array
    if (match) {
      newData.push(obj);
    }
  }

  return newData;
}

export function getSalesCompanies(allData) {
  return allData.filter(
    (dealer) => dealer._path && dealer._path.includes("company")
  );
}

export function groupDataByCountry(dealersData) {
  const transformedData = {};

  dealersData.forEach((dealer) => {
    const countryName = dealer.countryName.toLowerCase();

    if (!transformedData[countryName]) {
      transformedData[countryName] = {
        countryName: countryName,
        dealers: [],
      };
    }

    transformedData[countryName].dealers.push({
      companyName: dealer.companyName,
      address: dealer.address,
      phoneNumber: dealer.phoneNumber,
      fax: dealer.fax,
      email: dealer.email,
      industry: dealer.industry,
      brand: dealer.brand,
      category: dealer.category,
      region: dealer.region,
      longitude: dealer.longitude,
      latitude: dealer.latitude,
      webSiteUrl: dealer.webSiteUrl,
      webSiteLabel: dealer.webSiteLabel,
    });
    // sort company by companyName in alphabetical order
    transformedData[countryName].dealers.sort((a, b) =>
      a.companyName.localeCompare(b.companyName)
    );
  });

  const resObj = Object.values(transformedData);
  return resObj;
}
