import { Loader } from "@googlemaps/js-api-loader";
import { getSalesCompanies, transformDealersData } from "./utils";

export async function initMap(data, allData, apiKey) {
  const loader = new Loader({
    apiKey,
    version: "weekly",
    libraries: ["places"],
  });

  const loaderRes = await loader.importLibrary("maps");
  const { Map, InfoWindow } = loaderRes;
  const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary(
    "marker"
  );
  const salesData = getSalesCompanies(allData);
  const salesCompanyPinpoints = transformDealersData(salesData);
  const pinpoints = transformDealersData(data);
  if (pinpoints.length === 0) return;

  const map = new Map(document.getElementById("map"), {
    mapId: "4504f8b37365c3d0",
  });

  const infoWindow = new InfoWindow();

  const createMarker = (position, title, content, pinColor, i) => {
    const pin = new PinElement({
      glyph: `${i + 1}`,
      background: pinColor,
      borderColor: pinColor,
      // glyphColor: "white",
    });
    const marker = new AdvancedMarkerElement({
      position,
      map,
      title: `${title}`,
      content: pin.element,
    });

    marker.addListener("click", ({ domEvent, latLng }) => {
      const { target } = domEvent;

      infoWindow.close();
      infoWindow.setContent(content);

      infoWindow.open(marker.map, marker);
    });
  };

  const createContent = (title, address, phone) => `
    <div style="text-align: left; margin: 1rem;">
      <h2 style="font-weight: 700; margin-bottom: 0.5rem;">${title}</h2>
      <div style="margin-bottom: 0.5rem;">
        <span> ${address} </span>
      </div>
      <div style="margin-bottom: 0.5rem;">
        <span> ${phone} </span>
      </div>
    </div>
  `;

  const allMarkers = [...pinpoints, ...salesCompanyPinpoints];

  var bounds = new google.maps.LatLngBounds();
  for (var i = 0; i < allMarkers.length; i++) {
    bounds.extend(allMarkers[i].position);
  }

  map.fitBounds(bounds);

  // sales companies is blue, red is search results

  pinpoints.forEach.length > 0 &&
    pinpoints.forEach(({ position, title, phone, address }, i) => {
      const content = createContent(title, address, phone);
      createMarker(position, `${i + 1}. ${title}`, content, "", i);
    });
  salesCompanyPinpoints.length > 0 &&
    salesCompanyPinpoints.forEach(({ position, title, phone, address }, i) => {
      const content = createContent(title, address, phone);
      createMarker(position, `${i + 1}. ${title}`, content, "blue", i);
    });
}
